<!--
 * @Description: 项目详情
 * @Author: ChenXueLin
 * @Date: 2021-09-09 16:30:17
 * @LastEditTime: 2022-06-20 10:21:16
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="btnType"
            :btnText="'项目状态:' + detailInfo.projectStatusName"
          >
            <template v-slot:rightButtons>
              <el-button type="primary" @click="editTime"
                >修改关单核算时间</el-button
              >
              <!-- <el-button
                type="primary"
                @click="closeProject"
                v-if="detailInfo.projectStatus == 1"
                >关闭项目</el-button
              > -->
            </template>
          </detail-title-content>
          <!-- 基本信息 start -->
          <template>
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                label-width="140px"
                label-position="right"
                class="formBox"
              >
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="项目ID">
                      {{ detailInfo.projectId }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="项目名称">
                      {{ detailInfo.projectName }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="负责人">
                      {{ detailInfo.projectLeaderName }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="联系电话">
                      {{ detailInfo.phoneNo }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="创建人">
                      {{ detailInfo.createBy }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="创建时间">
                      {{ detailInfo.createdTime }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="关单核算时间">
                      {{ detailInfo.closeCalculateTime }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="备注">
                      {{ detailInfo.remark }}
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </template>
          <!-- 基本信息 end -->
          <!-- 关联任务单 -->
          <template>
            <div>
              <detail-table-title :showSearch="false">
                <template v-slot:title>
                  关联任务单
                </template>
                <template v-slot:btns>
                  <el-button type="primary" @click="showAddOrderDialog(1)"
                    >添加关联任务单</el-button
                  >
                  <el-button type="primary" @click="handleDeleteOrder"
                    >移除关联任务单</el-button
                  >
                </template>
              </detail-table-title>
              <el-form
                class="search-list"
                ref="bindTaskInfo"
                :model="bindTaskInfo"
              >
                <el-form-item class="search-item--1" prop="itemIdList">
                  <input-plus
                    v-model="bindTaskInfo.itemIdList"
                    valueType="Array"
                    placeholder="工单ID"
                    title="工单ID"
                    label="工单ID"
                    isNumber
                    clearable
                  ></input-plus>
                </el-form-item>
                <el-form-item class="search-item--buttons">
                  <el-button type="primary" @click="handleSearch"
                    >查询</el-button
                  >
                  <!-- <el-button class="reset" @click="handleReset">重置</el-button> -->
                </el-form-item>
              </el-form>
              <el-table
                :data="bindTaskTable"
                border
                highlight-current-row
                ref="bindTaskTable"
                @selection-change="handleOrderChange"
                :row-key="
                  row => {
                    return row.workOrderId;
                  }
                "
              >
                <el-table-column
                  type="selection"
                  width="50"
                  :reserve-selection="true"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  v-for="(column, index) in bindTaskColumnData"
                  :key="index"
                  :prop="column.fieldName"
                  :label="column.fieldLabel"
                  :min-width="column.width"
                  :fixed="column.fixed"
                  :align="column.align"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  fixed="right"
                  align="center"
                  label="操作"
                  width="100"
                >
                  <template slot-scope="scope">
                    <el-button type="text" @click="toTaskDetail(scope.row)"
                      >查看任务单</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 start -->
              <section class="pagination-wrapper fixed-section">
                <el-pagination
                  :page-size.sync="bindTaskInfo.pageSize"
                  :current-page.sync="bindTaskInfo.pageIndex"
                  :page-sizes="pageSizes"
                  :layout="layout"
                  :total="bindTaskInfo.total"
                  background
                  @size-change="val => handleSizeChange(1, val)"
                  @current-change="val => handleCurrentChange(1, val)"
                ></el-pagination>
              </section>
              <!-- 分页 end -->
            </div>
          </template>
          <!-- 项目人员 -->
          <!-- <template>
            <div>
              <detail-table-title :showSearch="false">
                <template v-slot:title>
                  项目人员
                </template>
                <template v-slot:btns>
                  <el-button
                    type="primary"
                    @click="handlePerson(1)"
                    v-if="detailInfo.projectStatus == 1"
                    >添加人员</el-button
                  >
                  <el-button type="primary" @click="deleteUser"
                    >移除人员</el-button
                  >
                </template>
              </detail-table-title>
              <el-table
                :data="projectUserList"
                border
                highlight-current-row
                @selection-change="handleUserChange"
                :row-key="
                  row => {
                    return row.userId;
                  }
                "
                ref="userTable"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  :reserve-selection="true"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  v-for="(column, index) in projectPersonColumnData"
                  :key="index"
                  :prop="column.fieldName"
                  :label="column.fieldLabel"
                  :min-width="column.width"
                  :fixed="column.fixed"
                  :align="column.align"
                  header-align="center"
                >
                </el-table-column>
              </el-table>
              <section class="pagination-wrapper fixed-section">
                <el-pagination
                  :page-size.sync="projectUser.pageSize"
                  :current-page.sync="projectUser.pageIndex"
                  :page-sizes="pageSizes"
                  :layout="layout"
                  :total="projectUser.total"
                  background
                  @size-change="val => handleSizeChange(3, val)"
                  @current-change="val => handleCurrentChange(3, val)"
                ></el-pagination>
              </section>
            </div>
          </template> -->
        </div>
      </section>
    </div>
    <!-- 添加关联任务单 -->
    <add-associated-order
      :projectId="projectId"
      :addOrderDialog="addOrderDialog"
      :orderDialogType="orderDialogType"
      @handleClose="handleClose"
      @refresh="getBindTaskTable"
      v-if="addOrderDialog"
    ></add-associated-order>
    <!-- 添加人员 -->
    <add-user
      :projectId="projectId"
      :selectPersonDialog="selectPersonDialog"
      @handleClose="handleClose"
      @refresh="getProjectUserRelListPage"
      v-if="selectPersonDialog"
    ></add-user>
    <!-- 修改核算时间 -->
    <el-dialog
      v-dialogDrag
      title="修改关单核算时间"
      :visible="editTimeDialog"
      width="500px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="closeEditTimeDialog"
      v-loading="dialogLoading"
      custom-class="edit-time-dialog"
    >
      <el-form
        :model="editTimeForm"
        :rules="editTimeFormRules"
        :inline="true"
        label-width="120px"
        ref="editTimeForm"
      >
        <el-form-item label="关单核算时间" prop="closeCalculateTime">
          <el-date-picker
            v-model="editTimeForm.closeCalculateTime"
            placement="bottom-start"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="关单核算时间"
            popper-class="special"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeEditTimeDialog">取消</el-button>
        <el-button type="primary" @click="handleSubmit"> 确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
// import { exportXlsx } from "@/utils/util";
import detailTitleContent from "@/components/detailTitleContent";
import DetailTableTitle from "@/components/detailTableTitle.vue";
import AddAssociatedOrder from "./components/addAssociatedOrder.vue";
import inputPlus from "@/components/inputPlus";
import {
  installColumnData,
  unInstallColumnData,
  projectPersonColumnData
} from "./columnData";
import addUser from "./components/addUser.vue";
import { printError } from "@/utils/util";

import {
  getProjectDetail, //获取项目基本信息
  getUninstallProjectList, //获取未安装项目仓
  getInstallProjectList, ////获取已安装项目仓
  getProjectUserRelListPage, //获取项目人员
  getProjectTaskListPage, //获取关联任务单列表
  deleteProjectTaskRel, //删除任务单
  setUpCloseCalculateTime,
  deleteProjectEmpRel, //删除人员
  closeProject //关闭项目
} from "@/api";

export default {
  name: "projectDetail",
  data() {
    return {
      loading: false,
      dialogLoading: false,
      projectId: "",
      detailInfo: {}, //基本信息
      /*****关联任务单*******/
      bindTaskInfo: {
        itemIdList: [],
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      bindTaskColumnData: [
        {
          fieldName: "itemId",
          display: true,
          fieldLabel: "工单ID",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskNumber",
          display: true,
          fieldLabel: "任务单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskSecondClassName",
          display: true,
          fieldLabel: "工单类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "itemStatusName",
          display: true,
          fieldLabel: "工单状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "areaName",
          display: true,
          fieldLabel: "所属地区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //关联订单表头
      bindTaskTable: [], //关联订单数据
      selectOrder: [], //勾选的关联任务单
      /******项目仓库********/
      projectWarehouse: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      isInstall: 0, //1是已安装0是未安装
      projectColumnData: [], //已安装设备表头
      installTable: [], //项目仓库数据
      /******项目人员********/
      projectPersonColumnData, //项目人员表头
      projectUserList: [], //项目人员数据
      selectUser: [], //勾选人员
      projectUser: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      /****添加、移除关联任务单 */
      addOrderDialog: false, //添加关联任务单弹框
      orderDialogType: 1, //1为添加任务单0移除任务单
      //****添加/移除设备 */
      addDeviceDialog: false,
      deviceDialogType: 1, //1为添加设备0移除设备
      /*****添加、移除人员 */
      selectPersonDialog: false,
      editTimeDialog: false,
      editTimeForm: {
        closeCalculateTime: ""
      },
      editTimeFormRules: {
        closeCalculateTime: [
          {
            required: true,
            message: "请选择核算时间",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base, listPage, listPageReszie],
  components: {
    detailTitleContent,
    DetailTableTitle,
    AddAssociatedOrder,
    addUser,
    inputPlus
  },
  computed: {
    btnType() {
      let value = "";
      if (this.detailInfo.projectStatus == 1) {
        value = "yellowBtn";
      }
      if (this.detailInfo.projectStatus == 2) {
        value = "blueBtn";
      }
      if (this.detailInfo.projectStatus == 3) {
        value = "greenBtn";
      }
      return value;
    }
  },
  watch: {
    isInstall: {
      immediate: true,
      handler(val) {
        if (val == 1) {
          this.projectColumnData = installColumnData;
        } else {
          this.projectColumnData = unInstallColumnData;
        }
      }
    }
  },
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.projectId = hasParamsRoute.params.projectId;
      if (this.projectId) {
        this.getDetailReq();
        this.getBindTaskTable();
        this.getUninstallProjectList();
        this.getProjectUserRelListPage();
      }
    },
    handleSearch() {
      this.bindTaskInfo.pageIndex = 1;
      this.getBindTaskTable();
    },
    //获取基本信息
    async getDetailReq() {
      try {
        let res = await getProjectDetail({ projectId: this.projectId });
        this.detailInfo = res.data;
      } catch (error) {
        printError(error);
      }
    },
    //获取关联任务单
    async getBindTaskTable() {
      try {
        this.loading = true;
        let res = await getProjectTaskListPage({
          ...this.bindTaskInfo,
          projectId: this.projectId
        });
        let bindTaskTable = res.data.array;
        this.bindTaskTable = bindTaskTable;
        this.bindTaskInfo.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //选中任务单
    handleOrderChange(columns) {
      this.selectOrder = columns;
    },
    handleDeleteOrder() {
      if (!this.selectOrder.length) {
        this.$message.warning("请先勾选任务单");
        return;
      }
      this.$confirm("是否确认移除任务单？", "移除任务单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteProjectTaskRel();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //删除任务单
    async deleteProjectTaskRel() {
      try {
        this.loading = true;
        let res = await deleteProjectTaskRel({
          projectTaskIdList: this.selectOrder.map(item => {
            return Object.assign(
              {},
              {
                id: item.projectTaskRelId,
                versionNumber: item.versionNumber
              }
            );
          })
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.selectOrder = [];
          this.$refs.bindTaskTable.clearSelection();
          this.getBindTaskTable();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    editTime() {
      this.editTimeDialog = true;
      this.editTimeForm.closeCalculateTime = this.detailInfo.closeCalculateTime;
    },
    //修改时间
    handleSubmit() {
      this.$refs.editTimeForm.validate(valid => {
        if (valid) {
          this.setUpCloseCalculateTimeReq();
        }
      });
    },
    //修改请求
    async setUpCloseCalculateTimeReq() {
      try {
        this.loading = true;
        let res = await setUpCloseCalculateTime({
          projectId: this.projectId,
          ...this.editTimeForm
        });
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.getDetailReq();
          this.closeEditTimeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    closeEditTimeDialog() {
      this.editTimeDialog = false;
      this.editTime = {
        closeCalculateTime: ""
      };
    },
    //查看任务单
    toTaskDetail(row) {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true,
          taskId: row.taskId
        }
      });
    },
    /*******项目仓库**********/
    switchPage(status) {
      if (this.isInstall == status) return;
      this.isInstall = status;
      this.projectWarehouse.pageIndex = 1;
      if (status == 0) {
        this.getUninstallProjectList();
      } else {
        this.getInstallProjectList();
      }
    },
    //获取未安装设备
    async getUninstallProjectList() {
      try {
        this.loading = true;
        let res = await getUninstallProjectList({
          ...this.projectWarehouse,
          projectId: this.projectId,
          ignoreProjectStatus: true
        });
        this.installTable = res.data.array;
        this.projectWarehouse.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取已安装设备
    async getInstallProjectList() {
      try {
        this.loading = true;
        let res = await getInstallProjectList({
          ...this.projectWarehouse,
          projectId: this.projectId
        });
        this.installTable = res.data.array;
        this.projectWarehouse.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /******项目人员*******/
    async getProjectUserRelListPage() {
      try {
        this.loading = true;
        let res = await getProjectUserRelListPage({
          ...this.projectUser,
          projectId: this.projectId
        });
        this.projectUserList = res.data.array;
        this.projectUser.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val) {
      if (val.id == 1) {
        this.routerPush({
          name: "userManage/companyDetail",
          params: {
            refresh: true
          }
        });
      }
    },
    // 页码改变
    handleCurrentChange(type, curPage) {
      this.pageIndex = curPage;
      this.scrollTop = 0;
      switch (type) {
        case 1: //任务单
          this.bindTaskInfo.pageIndex = curPage;
          this.getBindTaskTable();
          break;
        case 2: //项目仓库
          this.projectWarehouse.pageIndex = curPage;
          if (this.isInstall == 0) {
            this.getUninstallProjectList();
          } else {
            this.getInstallProjectList();
          }
          break;
        case 3: //项目人员
          this.projectUser.pageIndex = curPage;
          this.getProjectUserRelListPage();
          break;
      }
    },
    // 每页条数改变
    handleSizeChange(type, pageSize) {
      switch (type) {
        case 1: //任务单
          this.bindTaskInfo.pageIndex = 1;
          this.bindTaskInfo.pageSize = pageSize;
          this.getBindTaskTable();
          break;
        case 2: //项目仓库
          this.projectWarehouse.pageIndex = 1;
          this.projectWarehouse.pageSize = pageSize;
          if (this.isInstall == 0) {
            this.getUninstallProjectList();
          } else {
            this.getInstallProjectList();
          }
          break;
        case 3: //项目人员
          this.projectUser.pageIndex = 1;
          this.projectUser.pageSize = pageSize;
          this.getProjectUserRelListPage();
          break;
      }
    },
    //添加、移除关联任务单
    showAddOrderDialog(orderDialogType) {
      this.addOrderDialog = true;
      this.orderDialogType = orderDialogType;
    },
    //添加人员
    handlePerson() {
      this.selectPersonDialog = true;
    },
    //勾选人员
    handleUserChange(columns) {
      this.selectUser = columns;
    },
    deleteUser() {
      if (!this.selectUser.length) {
        this.$message.warning("请先勾选员工");
        return;
      }
      this.$confirm("是否确认移除人员？", "移除人员", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteProjectEmpRel();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //移除人员
    async deleteProjectEmpRel() {
      try {
        this.loading = true;
        let res = await deleteProjectEmpRel({
          projectEmpRelIdList: this.selectUser.map(item => {
            return Object.assign(
              {},
              {
                id: item.projectEmpRelId,
                versionNumber: item.versionNumber
              }
            );
          })
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.selectUser = [];
          this.$refs.userTable.clearSelection();
          this.getProjectUserRelListPage();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
    },
    closeProject() {
      this.$confirm("是否确认关闭项目？", "关闭项目", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.closeTaskReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //关闭项目请求
    async closeTaskReq() {
      try {
        this.loading = true;
        let res = await closeProject({
          projectIdList: [
            {
              id: this.detailInfo.projectId,
              versionNumber: this.detailInfo.versionNumber
            }
          ]
        });
        if (res.code == "OK") {
          this.$message.success("关闭成功");
          this.getDetailReq();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.formBox {
  margin-top: 15px;
}
/deep/ .edit-time-dialog {
  .el-dialog__body {
    padding: 20px;
  }
  .el-form {
    .el-input {
      width: 300px;
    }
  }
  .dialog-btn {
    width: 100px;
  }
}
</style>
